@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

$primary: #023852;/* MAIN COLOR */
$secondary: #071930; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

body {
    overflow-x: hidden;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    transition: all 0.25s ease-in-out;
    &.navbar-default#navScrolled {
        background: white;
        .navbar-nav {
        >li>a { 
            margin-top: 25px;
            @media (max-width: 767px) {
                margin-top: 0;
            }
            display: flex;
            align-items: center;
            color: black;
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
            }
        }
    }
 }
}
&.navbar-default.mainNav {
        background: transparent;
        .navbar-nav {
        >li>a { 
            color: white;
            text-align: center;
            margin-top: 25px;
            @media (max-width: 767px) {
                margin-top: 0;
            }
            display: flex;
            align-items: center;
            &:focus,
            &:active {
                background: transparent;
                color: white;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: white;
                    outline: 0;
            }
        }
    }
 }
}
&.navbar-default.privacyTermsNav {
        background: white;
        border-bottom: 2px solid $primary;
        .navbar-nav {
        >li>a { 
            color: black;
            text-align: center;
            margin-top: 25px;
            @media (max-width: 767px) {
                margin-top: 0;
            }
            display: flex;
            align-items: center;
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
            }
        }
    }
 }
}
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 150px;
    }
    .navbar-header a {
        float: left;
    }
}

.top-pad {
    padding: 0.5em;
    background: white;
    margin-top: 90px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.wrapper {
    // width: calc(100% - 45px - 45px);
    // width: 50%;
    position: relative;
    overflow: hidden;
    width: 100%;

    // left: 45px;
    .banner {
        background: url(../img/banner.jpg) no-repeat;
        background-size: cover;
        background-position: 100% 0%;
        height: 110vh;
        position: relative;

        @media (max-width: 1199px) {
            background: url(../img/banner_sm.jpg) no-repeat;
            background-size: cover;
            background-position: 50% 0%;
        }
        @media (max-width: 1024px) {
            background-attachment: scroll;
            height: 100vh;
        }
        @media (max-width: 991px) and (orientation: landscape) {
            height: 130vh;
        }
        @media (max-width: 800px) and (orientation: portrait) {
            height: 80vh;
        }
        background-attachment: fixed;

        .iphone {
            z-index: 1;
            left: 8%;
            width: 28vw;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 1199px) {
                display: none;
            }
        }
    }
    nav {
        width: inherit;
    }
    .navbar-default {
        background: transparent;
        @media (max-width: 767px) {
            background: white;
        }
        ul li a {
            color: white;
            @media (max-width: 767px) {
                color: black;
            }
        }
    }
}

.bg {
    // background: url(../img/samsung.png) no-repeat, linear-gradient(43deg, #2989d8 0%,#2989d8 50%, white 50%, white 100%),
    // no-repeat;
    background-position: 95% 100%;
    padding: 20rem 0 8rem 0;
    position: relative;
    margin-top: -360px;
    color: white;
    clip-path: polygon(0% 40%, 100% 0%, 100% 100%, 0% 100%);
    @media (max-width: 1199px) {
        clip-path: none;
        margin-top: 0;
        padding: 5rem 0 5rem 0;
        margin-top: 0;
    }
    @media (max-width: 991px) {
        padding: 5rem 0 0rem 0;
    }
    svg {
        position: absolute;
        height: 100%;
        top: 0;
    }
    .phone {
        position: absolute;
        right: 5%;
        z-index: 1;
        max-width: 35vw;
        width: 100%;
        background: url(../img/samsung.png) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
        bottom: 0;
        &:before {
            content: "";
            position: relative;
            width: 100%;
            display: block;
            margin-top: 86.4%;
            @media (max-width: 991px) {
                display: none;
            }
        }
        @media (max-width: 991px) {
            position: relative;
            background: none;
            max-width: 100%;
            right: 0;
            margin: 0 auto;
            img {
                margin: 0 auto;
                max-width: 350px;
                width: 100%;
            }
        }
    }
}

span[class*="ti"] {
    font-size: 3rem;
}

.iconSection {
    padding: 7rem 15px;
    @media (max-width: 991px) {
        padding: 3rem 15px;
    }
    .title {
        padding: 0 0 4rem 0;
    }
}

a.btn {
    @include btn;
}

.coupleSection {
    background: linear-gradient(
        rgba($primary, 0.5),
        rgba($secondary, 0.7)
        ),url(../img/banner2.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 0%;
    padding: 10rem 0;
    background-attachment: fixed;
    @media (max-width: 1024px) {
        background-attachment: scroll;
    }
    color: white;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .bg {
        clip-path: none;
        margin-top: 0px;
        padding: 8rem 0 4rem 0;
        @media (max-width: 991px) {
            padding: 4rem 0 0 0;
        }
    }
}

@supports (-ms-ime-align:auto) {
    .bg {
        clip-path: none;
        margin-top: 0px;
        padding: 8rem 0 4rem 0;
        @media (max-width: 991px) {
            padding: 4rem 0 0 0;
        }
    }
}

